.textoverimage {
  position: relative;
  text-align: center;
  color: $color-white;
}

.text-centered {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  &--image {
    top: 45%;
    @include respond(sm) {
      width: fit-content;
    }
  }
  &--inside {
    top: 48%;
    @include respond(sm) {
      width: fit-content;
    }
  }
}

.circle-inside-img {
  width: 70%;

  @include respond(sm) {
    width: 45%;
  }

  @include respond(phone) {
    visibility: hidden;
  }
}

.image-inside-img {
  width: 70%;
  @include respond(sm) {
    width: 45%;
    margin-top: 7%;
  }
  @include respond(phone) {
    margin-top: 10%;
  }
}

.butt-change-druggist {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include respond(sm) {
    position: relative;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    margin-top: 2%;
  }

  @include respond(phone) {
    position: relative;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    margin-top: 10%;
  }

  @include respond(phoneSmall) {
    position: relative;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    margin-top: 18%;
  }
}

.big-text {
  font-size: 1.3em;
}

.highligted-white {
  border-bottom: 3px solid $color-white;
  padding-bottom: 0.2em;
  @include respond(phone) {
    border-bottom: 3px solid black;
    color: black;
  }
}

.image-back {
  @include respond(sm) {
    visibility: hidden;
  }
}

.text-white {
  line-height: normal;
  @include respond(phone) {
    color: white;
  }
}
