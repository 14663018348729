.header-base {
  background-color: $color-white;
}

.header-row {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 10px;
}

.header-username {
  text-align: center;
  font-size: 150%;
  color: $grey-title;
}
.header-rounded-button {
  border-radius: 2em;
  padding-left: 1.2em;
  padding-right: 1.2em;
}

.header-admin-button {
  text-align: right;
}

.header-disconnect-button {
  font-size: 2em;
  color: grey;
}

.header-logo {
  width: 50%;
  height: 50%;
}
