.checkbox {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    justify-content: center;
}

.checkbox > span {
    color: #34495E;
    padding: 0.5rem 0.25rem;
}

.checkbox > input {
    height: 27px;
    width: 27px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #0069d9;
    border-radius: 4px;
    outline: none;
    transition-duration: 0.3s;
    background-color: #ffffff;
    cursor: pointer;
  }

.checkbox > input:checked {
    border: 1px solid #ffffff;
    background-color: #0069d9;
}

.checkbox > input:checked + span::before {
    content: '\2713';
    display: block;
    color: #ffffff;
    position: absolute;
    left: 0.4rem;
    top: 0rem;
}

.checkbox > input:active {
    border: 2px solid #34495E;
}