.td-quantities {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.input-quantities {
  width: 50%;
  text-align: center;
}

.input-textfield {
  width: 100%;
  text-align: center;
}

.table-container {
  width: 100% !important;
  padding-right: 30px !important;
}


.checkbox-container {
  width: 100% !important;
  height: 50% !important;
  text-align: center !important;
  line-height: 1.8rem !important;
}

.MuiDataGrid-main, .MuiDataGrid-columnHeaders {
  overflow: visible !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
  overflow-wrap: normal !important;
  white-space: normal !important;

  @include respond(tab-port) {
    line-height: 0.6rem;
    font-size: 0.6rem !important;
  }
}

.MuiDataGrid-cell, .MuiDataGrid-columnHeader {
  margin-left: 5px !important;  
  border: 1px solid rgb(151, 151, 151) !important;
  border-radius: 5px !important;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.2);
  height: 50px !important;
}

.MuiDataGrid-cell--textLeft {
  overflow-wrap: break-word !important;
  white-space: normal !important;
  line-height: 0.8rem;
  font-size: 0.8rem !important;
  max-lines: 3 !important;
  @include respond(tab-port) {
    line-height: 0.6rem;
    font-size: 0.6rem !important;
  }
}

.MuiDataGrid-root {
  border: none !important;
  overflow: visible !important;
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}

.MuiDataGrid-row {
  margin-top: 5px !important;
  overflow: visible !important;
}

.MuiTablePagination-displayedRows {
  margin-bottom: 0 !important;
}

.MuiDataGrid-virtualScroller {
  overflow: visible !important;
  margin-bottom: 50px !important;
}

.container-add-table {
  display: flex;
  justify-content: flex-end;
}

.select-dmos {
  width: 100%;
  margin-top: 3px;
  height: 38px;
  color: grey;
  border-color: lightgrey;
  border-radius: 5px;
  padding: 0px 10px;
}
