.title-card {
  color: $light-blue;
  font-size: 1.6em;
}

.default-card {
  height: 12em;
  border-radius: 0.8rem !important;
  cursor: pointer;
}

.card-content {
  color: $color-white;
  &--h2 {
    font-size: 1em;
  }
  &--h3 {
    font-size: 2em;
  }
}

.row-justify-content {
  justify-content: space-around;
}

.sub-card {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  color: $color-white;
  height: 22%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 0.8rem 0.8rem;
}

.col-center {
  text-align: center;
}

.card-img {
  width: 50%;
  @include respond(tab-port) {
    visibility: hidden;
  }
}

span.ceiling {
  font-size: initial;
}
