*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");

p title .title-card {
  background-color: $grey-background;
  font-family: "Libre Franklin";
  font-size: 3vh !important;
}

.row-no-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

@media (max-width: $phone-max-width) {
  .col-username {
    display: none;
  }
}
