.footer-base {
  position: relative;
  left: 0;
  background-color: $dark-blue;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.footer-flex {
  display: flex;
  align-items: center;
}

.footer-logo {
  width: 20%;
  height: 20%;
}
