$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

// COLORS
$color-white: white;
$dark-blue: rgb(7, 64, 143);
$light-blue: rgba(48, 181, 210, 1);
$grey-background: rgb(241, 241, 241);
$grey-title: rgb(105, 105, 105);

// SIZE
$phone-max-width: 576px;
